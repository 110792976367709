import React, { Component } from 'react';
import './Y0rtz.css';

import Carousel from 'react-bootstrap/Carousel'

function Y0rtz() {
    return (
            
            <div className="App-header2">

            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>Cl0rping M3M3's BRoThEr</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/Benina.PNG"
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <h3>Benina</h3>
                    <p>Benina is a baddy, you must obey the baddie. </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg"
                    alt="Third slide"
                    />
                    <Carousel.Caption>
                    <h3>OH YES DADDY &copy; </h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <br /> <br /> <br /> <br /> 

            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg" className="App-logo3" alt="logo" />
            <br />
            </div>
            
        );
    }

export default Y0rtz;