import './Navbar.css';

import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';


function Navbar(){
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }   else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();} ,[]);
 
     window.addEventListener('resize', showButton);

    return (
        <>

        <div class='NavBar-buttons' >
            <ul  class='NavBar' className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'> 
            <Link to='/' className='nav-links' class="active" onClick={closeMobileMenu}>
            H0me
            </Link>
            </li>

            <li className='nav-item'>
            <Link to='/Y0rtz' className='nav-links' onClick={closeMobileMenu}>
            Y0rtz
            </Link>
            </li>

            <li className='nav-item'>
            <Link to='/cl0rps' className='nav-links-mobile' onClick={closeMobileMenu}>
            Cl0rps
            </Link>
            </li>

            <li className='nav-item'>
            <Link to='/N0rtz' className='nav-links-mobile' onClick={closeMobileMenu}>
            N0rtz
            </Link>
            </li>
            
            </ul>
        </div>

        </>
    );
}

export default Navbar;