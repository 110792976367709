import React from 'react';
import './Body.css';

import useSound from 'use-sound';

 function Body(){

    const soundUrl = 'https://nte-na3-resources.s3-us-west-1.amazonaws.com/air-horn_1.mp3';
    const [playbackRate, setPlaybackRate] = React.useState(0.75);
    const [play] = useSound(soundUrl, {
        playbackRate,
        volume: 0.5,
        interrupt: true,
    });
    const handleClick = () => {
        if ({ playbackRate} >= 1)
        {
            setPlaybackRate(0.9);
            play();
        }
        else{
            setPlaybackRate(playbackRate + 0.1);
            play();
        } 
    };

    return (
        <>
        <div className="App-header">
            <br /><br /><br /><br />
        <img src="https://images.pexels.com/photos/39561/solar-flare-sun-eruption-energy-39561.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" className="App-logo" alt="logo" />
        <br /> <br />
        <div class='rainbow-text-div'>
        <p class="rainbow-text">
        YO YO YO
        You know what time it IS 
        </p>
        </div>
        <p class='clorpage-text'> I'll Clorp Your M3M3, HO</p>
        
            <button onClick={handleClick}>
            <span role="img" aria-label="Heart">
                💖
            </span>
            </button>
            <br /><br /><br /><br /><br /><br />
        </div>
        </>
    );
}

export default Body;