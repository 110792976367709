import React, { Component } from 'react';
import './cl0rps.css';

import useSound from 'use-sound';
import Button from 'react-bootstrap/Button'


function Cl0rps() {

    const soundUrl = 'https://nte-na3-resources.s3-us-west-1.amazonaws.com/its-a-very-nice.mp3';
    const [playbackRate, setPlaybackRate] = React.useState(0.75);
    const [play] = useSound(soundUrl, {
        playbackRate,
        volume: 0.5,
        interrupt: true,
    });
    const handleClick = () => {
        play();
    };


    return (
            
        <div className="App-header4">
        
        <br /><br /><br /><br />
        <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/The+Land+of+DhorDhor.jpg" className="App-logo4" alt="logo" />
        <br /> < br />
        <Button variant="primary" size="lg" onClick={handleClick}>
        ...Very Nice!
        </Button>

        
        </div>
            
        );
    }

export default Cl0rps;