import React, { Component } from 'react';
import './N0rtz.css';


function N0rtz() {
    return (
            
            <div className="App-header">
            <br /><br /><br />
            <h1>N0rted to the depths of Mount Dhor Dhor...</h1>
            <img src="https://nte-na3-resources.s3-us-west-1.amazonaws.com/The+Ring.jfif" className="App-logo2" alt="logo" />
            
            <br />
            <div>
                
                <p> OH No, it's the Big N0rtzzzzzzzzzz </p>

            </div>
            
            </div>
            
        );
    }

export default N0rtz;