import React from 'react';
import './App.css';

import Navbar from './Navbar';
import Body from './Body';

import Y0rtz from './Y0rtz';
import cl0rps from './Cl0rps';
import N0rtz from './N0rtz';

import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header>
      <Router>
      <Navbar/>
      <Switch>
        <Route path='/' exact component={Body}/>
        <Route path='/Y0rtz' exact component={Y0rtz}/>
        <Route path='/Cl0rps' exact component={cl0rps}/>
        <Route path='/N0rtz' exact component={N0rtz}/>
      </Switch>
      </Router>
      </header> 
         
    </div>
  );
}

export default App;
